#root, .rootLayout {
  height: 100%;
}

header.ant-layout-header {
  padding: 0;
}

.rootMenu {
  display: inline-block;
  width: 10em;
}

#username {
  color: white;
  text-align: right;
  display: inline-block;
  width: calc(100% - 12em);
}

.musicPlayer-statusBar {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}
.musicPlayer-statusBar-status {
  display: inline-block;
  width: 2.5em;
  vertical-align: top;
}
.musicPlayer-status--true {
  color: darkgreen;
}
.musicPlayer-status--false {
  color: darkred;
}
.musicPlayer-statusBar-text {
  display: inline-block;
  width: calc(100% - 14.5em);
}

.musicPlayer-statusBar-actions {
  display: inline-block;
  width: 12em;
  vertical-align: top;
}

.musicPlayer-volumePopup {
  height: 20em;
  width: 2em;
}

.musicPlayer-volumePopup-overlay {
  background-color: white;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
}

.musicPlayer-radiostation {
  margin-bottom: .25em;
}